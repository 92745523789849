import moment from "moment";
import { useState, useEffect } from "react";
import { HttpQueryFilter } from "src/api/access/Authority";
import { Training, GeneralTask, TrainingsClient, GeneralTasksClient } from "src/api/stable/Booking";
import { StableUserAbsence, StableUserAbsencesClient, TrainingType, TrainingTypeTranslation } from "src/api/stable/Stable";
import CalendarWeekController from "src/components/Calendar/CalendarWeekController";
import CalendarWeekView, { AvailableHours, CalendarEvent } from "src/components/Calendar/CalendarWeekView";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import useUser from "src/hooks/useUser";
import TaskModal from "./TaskModal";
import useConfigurationState from "src/hooks/useConfigurationState";
import _ from "lodash";

const AgendaWeek = () => {
  const user = useUser();
  const [modalVisible, setModalVisible] = useState(false);
  const [currentTask, setCurrentTask] = useState<GeneralTask | undefined>();
  const [initialStart, setInitialStart] = useState<Date>();
  const [trainings, setTraingings] = useState<Training[]>([]);
  const [tasks, setTasks] = useState<GeneralTask[]>([]);
  const [absences, setAbsences] = useState<StableUserAbsence[]>([]);
  const apiConfiguration = useApiConfiguration();
  const trainingsClient = new TrainingsClient(apiConfiguration);
  const tasksClient = new GeneralTasksClient(apiConfiguration);
  const absencesClient = new StableUserAbsencesClient(apiConfiguration);
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const [startCalendar, setStartCalendar] = useState(moment().startOf('isoWeek').toDate());

  useEffect(() => {
    if (!user?.id) return;
    trainingsClient
      .get([{ property: 'instructor.userId', value: user?.id, type: '=' } as HttpQueryFilter], [], 100, 0, undefined, undefined)
      .then(response => setTraingings(response.items ?? []));
    absencesClient
      .get([{ property: 'user.userId', value: user?.id, type: '=' } as HttpQueryFilter], [], 1000, 0, undefined, undefined)
      .then(response => setAbsences(response.items ?? []));
    tasksClient
      .get([{ property: 'user.userId', value: user?.id, type: '=' } as HttpQueryFilter], [], 1000, 0, undefined, undefined)
      .then(response => setTasks(response.items ?? []));
  }, [user?.id])

  const configurationState = useConfigurationState();
  const hours: AvailableHours[] = _.map(
    configurationState?.booking?.hours,
    (v, k) => ({ key: Number(k), start: [Number(v.start[0]), Number(v.start[1])], end: [Number(v.end[0]), Number(v.end[1])] })
  )
    .sort((a, b) => a.key - b.key)
    .map(v => ({ start: v.start, end: v.end }));

  const calendarEvents: CalendarEvent[] = [
    ...trainings.map(training => ({
      id: training.id!,
      start: training.start!,
      end: training.end!,
      label: trainingTypeTranslation.getCurrentTranslation(training.type)?.name ?? "Training",
      colorName: "indigo",
      active: true
    } as CalendarEvent)),
    ...tasks.map(task => ({
      id: task.id!,
      start: task.start!,
      end: task.end!,
      label: task.title,
      colorName: "amber",
      active: true
    } as CalendarEvent)),
    ...absences.map(absence => ({
      id: absence.id!,
      start: absence.start!,
      end: absence.end!,
      label: "Nieobecność",
      colorName: "red",
      active: true
    } as CalendarEvent))
  ];

  const onClickTerm = (start: Date, _end: Date) => {
    setInitialStart(start);
    setModalVisible(true);
  }

  function onTaskCreated(task: GeneralTask): void {
    if (tasks.some(t => t.id === task.id)) {
      setTasks([...tasks].map(t => t.id === task.id ? task : t));
    } else {
      setTasks([...tasks, task]);
    }
    setCurrentTask(undefined);
  }

  function onTaskDeleted(taskId: string): void {
    setTasks([...tasks.filter(t => t.id !== taskId)]);
    setCurrentTask(undefined);
  }

  function onClickEvent(event: CalendarEvent): void {
    setCurrentTask(tasks.find(t => t.id === event.id));
    setModalVisible(true);
  }

  return (
    <>
      <div className="cleafix">
        <div className="float-end">
          <CalendarWeekController currentDate={startCalendar} onChangeDate={setStartCalendar} />
        </div>
      </div>
      <CalendarWeekView
        startCalendar={startCalendar}
        startHour={_.min(hours.map(h => h.start[0])) || 7}
        endHour={_.max(hours.map(h => h.end[1] ? h.end[0] + 1 : h.end[0])) || 18}
        events={calendarEvents}
        availableHours={hours}
        onClickTerm={onClickTerm}
        onClickEvent={onClickEvent}
        className="bg-white overflow-hidden w-full h-full"
        termInterval={30}
        termGranulation={30}
      />
      <TaskModal visible={modalVisible} setVisible={setModalVisible} initialStart={initialStart} onTaskCreated={onTaskCreated} onTaskDeleted={onTaskDeleted} task={currentTask} />
    </>
  )
}

export default AgendaWeek;